import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import ArrowButton from '../../components/Common/ArrowButton'
import ModalIframe from '../../components/Common/ModalIframe'
import cn from 'classnames'

import YoutubePlayIcon from '../../svg/youtube-play-icon'

import './OctoberDigest2024.styles.scss'
import * as s from '../../pages/news.module.scss'
import ModalImage from '../../components/Common/ModalImage'

const OctoberDigest2024 = () => {
  const [modalVideoData, setModalVideoData] = React.useState<{
    title: string
    src: string
  }>()
  const [imageData, setImageData] = React.useState<{
    title: string
    image: string
  }>()
  const swiperRef = React.useRef<SwiperRef>(null)
  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  const activitiesData = [
    {
      imageId: 'image1',
      alt: 'Activities-photo-1',
    },
    {
      imageId: 'image2',
      alt: 'Activities-photo-2',
    },
    {
      imageId: 'image3',
      alt: 'Activities-photo-3',
    },
    {
      imageId: 'image4',
      alt: 'Activities-photo-4',
    },
  ]
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="Digest of the October updates on SIGNAX products"
        description="SIGNAX October 2024 Digest of updates and product materials, including SIGNAX DOCS CDE, INSPECTION - remote construction control service, DASHBOARD project management software and TOOLS solution for BIM management automation"
      />
      <NewsSectionBlock date="October 15, 2024">
        <Typography variant="h1" color="blue">
          October Digest SIGNAX
        </Typography>
        <Typography variant="h3" size={18}>
          General:
        </Typography>
        <Typography variant="body1">
          This October, we made significant improvements at{' '}
          <span style={{ fontWeight: 500 }}>SIGNAX</span>, primarily focusing on
          enhancing our <span style={{ fontWeight: 500 }}>DOCS</span> Common
          Data Environment. We unveiled three new product videos on our social
          media, along with two engaging #DevNews posts that spotlight our{' '}
          <span style={{ fontWeight: 500 }}>latest updates</span>. We are
          excited to announce that our website and modules are available in{' '}
          <span style={{ fontWeight: 500 }}>Spanish</span>, further{' '}
          <span style={{ fontWeight: 500 }}>expanding</span> our global reach
          and accessibility. Our commitment to providing{' '}
          <span style={{ fontWeight: 500 }}>top-notch solutions</span> in
          construction management continues to grow!
        </Typography>
        <Typography variant="h3" size={18}>
          DOCS:
        </Typography>
        <Typography variant="body1">
          Our latest update to{' '}
          <span style={{ fontWeight: 500 }}>SIGNAX DOCS</span> introduced
          several <span style={{ fontWeight: 500 }}>powerful new features</span>{' '}
          to improve{' '}
          <span style={{ fontWeight: 500 }}>workflow efficiency</span>. We’ve
          added a <span style={{ fontWeight: 500 }}>Gantt chart viewer</span>{' '}
          for easier schedule management, and a video explaining{' '}
          <span style={{ fontWeight: 500 }}>document management</span>,
          including <span style={{ fontWeight: 500 }}>version control</span> for
          PDF and BIM models, file comparisons, and tools to help{' '}
          <span style={{ fontWeight: 500 }}>track and analyze changes.</span>
        </Typography>

        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAX DOCS. Document management',
              src: 'https://youtube.com/embed/XRW9cc8YdC0?si=MzE1ycxixWeaNY_b',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview1.childImageSharp.gatsbyImageData}
            alt="SIGNAX DOCS. Document management"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="body1">
          Another video showcases{' '}
          <span style={{ fontWeight: 500 }}> collaboration enhancements </span>{' '}
          that empower teams to manage{' '}
          <span style={{ fontWeight: 500 }}>reviews</span> more effectively,
          ensuring{' '}
          <span style={{ fontWeight: 500 }}>seamless communication</span> and{' '}
          <span style={{ fontWeight: 500 }}>compliance</span> throughout the
          project lifecycle.
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAX DOCS. Reviews workflow',
              src: 'https://youtube.com/embed/Zo08KUSOpZc?si=WlAA08JUUfhCCr4-',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview2.childImageSharp.gatsbyImageData}
            alt="SIGNAX DOCS. Reviews workflow"
          />
          <YoutubePlayIcon />
        </div>

        <Typography variant="h3" size={18}>
          TOOLS:
        </Typography>
        <Typography variant="body1">
          We are proud to announce advancements in our{' '}
          <span style={{ fontWeight: 500 }}>TOOLS module</span>, particularly
          focusing on{' '}
          <span style={{ fontWeight: 500 }}>autoprocessing capabilities</span>.
          This feature allows{' '}
          <span style={{ fontWeight: 500 }}>BIM Managers</span> to{' '}
          <span style={{ fontWeight: 500 }}>automate workflows</span> by
          scheduling tasks, processing models{' '}
          <span style={{ fontWeight: 500 }}>without manual intervention</span>,
          and conducting <span style={{ fontWeight: 500 }}>batch runs</span> for
          validation and classification. These enhancements are designed to{' '}
          <span style={{ fontWeight: 500 }}>save time</span> and reduce{' '}
          <span style={{ fontWeight: 500 }}>routine tasks</span>, enabling users
          to focus on <span style={{ fontWeight: 500 }}>what matters most</span>{' '}
          in their projects.
        </Typography>
        <Typography variant="body1">
          Watch our new video and contact us if you want to{' '}
          <span style={{ fontWeight: 500 }}>
            streamline your BIM management
          </span>
          :
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAX TOOLS. BIM Manager routine automatization',
              src: 'https://youtube.com/embed/Hfm00pKAfrQ?si=Fb8vSRchI63e-b1g',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview3.childImageSharp.gatsbyImageData}
            alt="SIGNAX TOOLS. BIM Manager routine automatization"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="h3" size={18}>
          Activities:
        </Typography>
        <Typography variant="body1">
          This October, SIGNAX participated in two{' '}
          <span style={{ fontWeight: 500 }}>significant events</span> focused on
          the <span style={{ fontWeight: 500 }}>future of construction</span>{' '}
          and engineering. At the{' '}
          <span style={{ fontWeight: 500 }}>
            Digital Construction Summit 2024
          </span>{' '}
          in Dubai Marina, industry leaders shared insights on{' '}
          <span style={{ fontWeight: 500 }}>
            technology’s transformative role
          </span>{' '}
          in the sector, highlighting the potential of{' '}
          <span style={{ fontWeight: 500 }}>Contech IT startups</span> and
          discussing <span style={{ fontWeight: 500 }}>AI’s impact</span> on
          asset quality, as well as the challenges posed by{' '}
          <span style={{ fontWeight: 500 }}>urbanization</span> and{' '}
          <span style={{ fontWeight: 500 }}>climate change</span>.
        </Typography>
        <Typography variant="body1">
          Additionally, at the{' '}
          <span style={{ fontWeight: 500 }}>MEAEC BuildTechXchange Summit</span>
          , key takeaways included the rise of{' '}
          <span style={{ fontWeight: 500 }}>mixed-use developments</span>, the{' '}
          <span style={{ fontWeight: 500 }}>revolutionizing effects</span> of{' '}
          <span style={{ fontWeight: 500 }}>digital transformation</span> and{' '}
          <span style={{ fontWeight: 500 }}>AI</span>, and the growing trend of{' '}
          <span style={{ fontWeight: 500 }}>modular construction</span> in the
          Middle East. These events provided valuable perspectives that we are
          excited to integrate into our upcoming projects.
        </Typography>
        <div className="flex items-center">
          <ArrowButton
            onClick={() => swiperRef.current?.swiper.slidePrev()}
            disabled={disabledNavigationPrev}
            arrowType="left"
            className="october-digest_btn"
          />
          <Swiper
            ref={swiperRef}
            freeMode={true}
            grabCursor={true}
            navigation={true}
            onSlideChange={swiper => {
              setDisabledNavigationPrev(swiper.isBeginning)
              setDisabledNavigationNext(swiper.isEnd)
            }}
            pagination={{
              clickable: true,
              bulletClass: 'october-digest_pagination',
              bulletActiveClass: 'active',
            }}
            modules={[Pagination]}
          >
            {activitiesData.map((item, idx) => (
              <SwiperSlide key={idx}>
                <div
                  className="flex flex-col items-center mb-6"
                  onClick={() =>
                    setImageData({
                      title: item.alt,
                      image: item.imageId,
                    })
                  }
                >
                  <GatsbyImage
                    image={
                      images[item.imageId + `Preview`].childImageSharp
                        .gatsbyImageData
                    }
                    alt={item.alt}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <ArrowButton
            arrowType="right"
            onClick={() => swiperRef.current?.swiper.slideNext()}
            disabled={disabledNavigationNext}
            className="october-digest_btn"
          />
        </div>
        <Typography variant="h3" size={18}>
          International projects:
        </Typography>
        <Typography variant="body1">
          We also took part in an{' '}
          <span style={{ fontWeight: 500 }}> archaeological expedition</span>{' '}
          near the ancient city of Ur, where our team, led by{' '}
          <span style={{ fontWeight: 500 }}>Andrei Serafimin</span>, conducted
          essential <span style={{ fontWeight: 500 }}>surveying tasks</span> and
          captured{' '}
          <span style={{ fontWeight: 500 }}>
            high-resolution orthomosaic images
          </span>{' '}
          to aid in excavation planning. This initiative showcases our
          commitment to using{' '}
          <span style={{ fontWeight: 500 }}>
            technology to preserve history
          </span>{' '}
          and enhance <span style={{ fontWeight: 500 }}>cultural heritage</span>
          .
        </Typography>
        <div
          className={cn(s.imageClickable, s.imageWrap)}
          onClick={() =>
            setImageData({
              title: `SIGNAX Iraqi's archeological expidition`,
              image: 'image5',
            })
          }
        >
          <GatsbyImage
            image={images.image5Preview.childImageSharp.gatsbyImageData}
            alt="SIGNAX Iraqi's archeological expidition"
          />
        </div>
      </NewsSectionBlock>
      {modalVideoData && (
        <ModalIframe
          title={modalVideoData.title}
          src={modalVideoData.src}
          srcTitle={modalVideoData.title}
          onClose={() => setModalVideoData(undefined)}
        />
      )}
      {imageData && (
        <ModalImage
          title={imageData.title}
          onClose={() => setImageData(undefined)}
        >
          <GatsbyImage
            image={images[imageData.image].childImageSharp.gatsbyImageData}
            alt={imageData.title}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
              maxHeight: ' calc(100vh - 100px)',
            }}
          />
        </ModalImage>
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    videoPreview1: file(
      relativePath: { eq: "news/october-digest-2024/video-preview-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview2: file(
      relativePath: { eq: "news/october-digest-2024/video-preview-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview3: file(
      relativePath: { eq: "news/october-digest-2024/video-preview-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image1: file(relativePath: { eq: "news/october-digest-2024/image-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image1Preview: file(
      relativePath: { eq: "news/october-digest-2024/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "news/october-digest-2024/image-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image2Preview: file(
      relativePath: { eq: "news/october-digest-2024/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "news/october-digest-2024/image-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image3Preview: file(
      relativePath: { eq: "news/october-digest-2024/image-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }

    image4: file(relativePath: { eq: "news/october-digest-2024/image-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image4Preview: file(
      relativePath: { eq: "news/october-digest-2024/image-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "news/october-digest-2024/image-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image5Preview: file(
      relativePath: { eq: "news/october-digest-2024/image-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
  }
`

export default OctoberDigest2024
